//@ts-nocheck
import Image from 'next/image'
import { FC } from 'react'
import ComponentWrapper from '../wrapper/ComponentWrapper'

interface ServicesProps {}

const features = [
  {
    name: 'Professionelle Zahnreinigung',
    description:
      'Bei einer professionellen Zahnreinigung reinigen, polieren und floridieren wir die Zähne. Damit werden Erkrankungen effektiv vorgebeugt und Sie erhalten ein glattes Gefühl auf den Zähnen.',
  },
  {
    name: 'Parodontologie',
    description:
      'Die Ursache ist häufig eine Entzündung Ihres Zahnfleisches. Die Therapie erfolgt durch Instruktion und Anpassung der Mundhygienemittel sowie einer perfekten Entfernung aller harten und weichen Zahnbeläge ober- und unterhalb des Zahnfleisches.',
  },
  {
    name: 'Ästhetische Zahnheilkunde',
    description:
      ' Ästhetik in der Zahnheilkunde ist das Ergebnis gründlicher Planung und zielgerichteter Vorbehandlung. Unter der Vielzahl der therapeutischen Möglichkeiten wie Veneers, Non-prep Veneers, Kronen und Teilkronen, auch hochästhetischer Vollkeramik oder der Restauration mit Kompositen, wählen wir zusammen die für Sie beste Möglichkeit aus.',
  },
  {
    name: 'Implantatprothetik',
    description:
      'Implantate geben uns die Möglichkeit, Ihre verloren gegangene Zahnwurzel zu ersetzen. Sie stellen trotz eines chirurgischen Eingriffs oftmals die schonendste Form der rekonstruktiven Zahnmedizin dar!',
  },
  {
    name: 'Digitale Abformung',
    description:
      'Bei der digitalen Abformung werden die Zähne mit einer Kamera erfasst und es entsteht ein digitales, dreidimensionales Modell des Kiefers. Unsere Zahntechniker:innen können sofort mit der Herstellung der Krone beginnen (CAD/CAM) und die Tragedauer von Provisorien verkürzt sich erheblich.',
  },
  {
    name: 'Invisalign',
    description:
      'Mit Invisalign-Schienen lässt sich im Vergleich zur Multibandbehandlung eine unauffällige Korrektur der Zähne und des Kiefers vornehmen. Sehr praktisch: Die Zahnpflege bleibt die Gleiche wie vorher.',
  },
]

const Services: FC<ServicesProps> = ({}) => {
  return (
    <div className='w-full'>
      <div aria-hidden='true' className='relative h-32 md:h-44 lg:h-52 w-full'>
        {/* <CustomImage src='/dentist-cover.jpg' alt='logo' layout='fill' width='1920px' /> */}
        <Image
          lazyBoundary='-1px'
          src='/dentist-cover.jpg'
          quality={90}
          layout='fill'
          objectFit='cover'
          alt='Zahnarzt an der Praxis am Bulkamp'
        />
        <div className='absolute inset-0 bg-gradient-to-t from-gray-50' />
      </div>

      <div className='relative -mt-12 max-w-7xl mx-auto pb-16 px-4 sm:pb-24 sm:px-6 lg:px-8'>
        <div className='max-w-2xl mx-auto text-center lg:max-w-4xl'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            Vielfältige Leistungen
          </h2>
          <p className='mt-4 text-gray-500'>
            Unsere Leistungen basieren auf den aktuellen Erkenntnissen der Zahnheilkunde. Das
            Behandlungsspektrum deckt alle notwendigen und förderlichen Bereiche der Vorsorge, Fürsorge und
            Nachsorge ab.
          </p>
        </div>

        <dl className='mt-16 max-w-2xl mx-auto grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:max-w-none lg:grid-cols-3 lg:gap-x-8'>
          {features.map((feature) => (
            <div key={feature.name} className='border-t border-gray-200 pt-4'>
              <dt className='font-medium text-gray-900'>{feature.name}</dt>
              <dd className='mt-2 text-sm text-gray-500'>{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}

export default ComponentWrapper(Services, 'services', 'bg-gray-50')
